!((document, $) => {
  'use strict';

  /**
   * Header top menu moves to the main menu for desktop view
   * and revert back the previous position
   * on window resize or first load.
   */
  function topMenuPosition() {
    if ($(window).width() > 990) {
      $('.l-header-inner .block-menu-menu-header-top-menu').prependTo(
        '.l-header-inner .block-system-main-menu'
      );
    }
    else {
      $('.l-header-inner .block-menu-menu-header-top-menu').insertAfter(
        '.l-header-inner .block-system-header'
      );
    }
  }

  // Header top menu move to main menu
  $(document).ready(function() {
    topMenuPosition();

    // Sticky header functionality
    var headerHeight = $('.l-header').outerHeight();
    var layoutMinHeight = $(window).height() - headerHeight;
    $('body').css('padding-top', headerHeight);
    $('.layout').css('min-height', layoutMinHeight);
    $(window).scroll(function() {
      if ($(document).scrollTop() > 1) {
        $('body').addClass('scrolled');
      }
      else {
        $('body').removeClass('scrolled');
      }
    });

    $(window).resize(function() {
      topMenuPosition();
    });

    // hide the mobile header on scroll down and fixed it again on scroll up
    var lastScrollTop = 0;
    $(window).scroll(function() {
      var scrollTop = $(this).scrollTop();
      if (scrollTop > 245) {
        $('.l-header').addClass('scroll');
        $('.l-header').removeClass('no-scroll');
      }
      if (lastScrollTop > scrollTop) {
        $('body').css('padding-top', headerHeight);
        $('.l-header').addClass('no-scroll');
        $('.l-header').removeClass('scroll');
      }
      lastScrollTop = scrollTop;
    });
  });
})(document, jQuery);
